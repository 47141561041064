.App {
  /* background-color: #212727; */
  min-height: 100vh;
  padding: 0;
  font-size: 16px;
}
@font-face {
  font-family: 'Mabry';
  src: local('Mabry'), url(./fonts/Mabry-Pro.otf) format('opentype');
}
/* @font-face {
  font-family: 'Mabry';
  font-weight: 400;
  src: url(./fonts/Mabry-Pro-Black-Italic.otf) format('opentype');
} */

@font-face {
    font-family: 'Mabry';
    font-weight: 900;
    src: local('Mabry'), url(./fonts/Mabry-Pro-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Mabry';
    font-weight: 400;
    src: local('Mabry'), url(./fonts/Mabry-Pro-Black.otf) format('opentype');
}

/* @font-face {
  font-family: 'Mabry';
  font-weight: 900;
  src: local('Mabry'), url(./fonts/Mabry-Pro-Bold-Italic.otf) format('opentype');
} */

/* @font-face {
  font-family: 'Mabry';
  font-weight: 400;
  src: local('Mabry'), url(./fonts/Mabry-Pro-Italic.otf) format('opentype');
} */

/* @font-face {
  font-family: 'Mabry';
  font-weight: 400;
  src: local('Mabry'), url(./fonts/Mabry-Pro-Light-Italic.otf) format('opentype');
} */

@font-face {
  font-family: 'Mabry';
  font-weight: 400;
  src: local('Mabry'), url(./fonts/Mabry-Pro-Light.otf) format('opentype');
}

/* @font-face {
  font-family: 'Mabry';
  font-weight: 700;
  src: local('Mabry'), url(./fonts/Mabry-Pro-Medium-Italic.otf) format('opentype');
} */

@font-face {
  font-family: 'Mabry';
  font-weight: 700;
  src: local('Mabry'), url(./fonts/Mabry-Pro-Medium.otf) format('opentype');
}
body{
  font-family: 'Mabry' !important;
  background-color: #fff;
}
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

.MuiPopover-paper {
  min-width: 370px !important;
  max-height: 400px !important;
  font-family: 'Mabry' !important;
}
.optionItems {
  display: block !important;
  text-align: center;
  font-family: 'Mabry' !important;
  font-size: 1.3rem;
}
.MuiPopover-paper .Mui-selected {
  background-color: #eb008b !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
